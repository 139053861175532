<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-3/3">
          <div class="vx-card p-6 mb-6">
            <ItenaryForm></ItenaryForm>
          </div>
            <!-- Save & Reset Button -->
          <div class="vx-card p-4 mb-4">
              <div class="flex flex-wrap items-center justify-end">
                <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
              </div>
          </div>
        </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import ItenaryForm from './common/ItenaryForm';
import { mapGetters, mapActions } from 'vuex'
import loaderMixin from '../../../../mixins/loaderMixin';

export default {
  components: {
    vSelect,
    ItenaryForm
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        itenaryForm: 'main_package/getItenaryFormObj',
    }),
    getPackageId() {
        return this.$route.params.packageId;
    },
  },
  created(){
      this.clearItenaryForm();
      this.clearValidationErrors();
    },
  methods: {
    ...mapActions({
      createPackageItenary: 'main_package/createPackageItenary',
      clearItenaryForm: 'main_package/clearItenaryForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    submitForm() {
      this.openLoading();
      this.createPackageItenary({packageId: this.getPackageId, data: this.itenaryForm})
        .then(response => {
           if(response.status === 201) {
            this.closeLoading();
            this.$vs.notify({ 
                title: 'Itenary Created',
                text: 'Itenary created successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push("/packages/" + this.getPackageId + "/view");
           }
        }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
