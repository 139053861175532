<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Package Itenary Details</span>
      </div>
      <div class="vx-col w-full">
        <div class="flex flex-wrap -mx-3 mb-6">
            <vs-input 
            class="w-full md:w-2/2 px-3 mb-6 md:mb-0" 
            label="Title" 
            :danger="validation.title?true:false"
            :danger-text="validation.title?validation.title[0]:''"
            :value="itenaryForm.title"
            @input="updateItenaryForm({key: 'title', value: $event})" />
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
            <vs-input
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            label="Duration"
            :danger="validation.duration?true:false"
            :danger-text="validation.duration?validation.duration[0]:''"
            :value="itenaryForm.duration"
            @input="updateItenaryForm({key: 'duration', value: $event})" />
            <vs-input 
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0" 
            label="Accommodation" 
            :danger="validation.accommodation?true:false"
            :danger-text="validation.accommodation?validation.accommodation[0]:''"
            :value="itenaryForm.accommodation"
            @input="updateItenaryForm({key: 'accommodation', value: $event})" />
        </div>

        <label>Description</label>
        <div class="w-full mt-4">
        <ckeditor
          :editor="editor"
          :config="editorConfig"
          :value="itenaryForm.description"
          @input="updateItenaryForm({key: 'description', value: $event})"></ckeditor>
        <span class="text-danger text-sm"
          v-show="validation.description?true:false">
          {{ validation.description?validation.description[0]:''}}
        </span>
        </div>
      </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {  
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // toolbar: [ 'bold', 'italic', '|', 'link' ]
      }
    }
  },
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        itenaryForm: 'main_package/getItenaryFormObj',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateItenaryForm: 'main_package/updateItenaryForm',
    })
  }
}
</script>
